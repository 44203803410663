import React, { useState } from "react";
import {
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
} from "@material-ui/core";
import { Link as GLink, navigate } from "gatsby";
import Copyright from "../components/Copyright";
import Logo from "../components/Logo";
import useStyles from "../helpers/styles";
import Wrapper from "../helpers/FormWrapper";
import * as CognitoService from "../services/cognito.service.js";
import config from "../config";

const ConfirmationForm = ({ location }) => {
  const classes = useStyles();
  const [code, setCode] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [codeError, setCodeError] = useState(null);

  const onSubmit = () => {
    if (code && code.length === 6) {
      CognitoService.confirmUser(code)
        .then(function (result) {
          setCodeError(null);
          setFeedback("Congratulations. Welcome to MapQuest!");
          setTimeout(() => {
            navigate("/");
          }, 2500);
        })
        .catch(function (error) {
          setCodeError(error.message);
        });
    } else {
      setCodeError("Invalid verification code");
    }
  };

  const resendCode = () => {
    CognitoService.resendConfirmationCode()
      .then(function () {
        setFeedback("New code has been sent to your email.");
      })
      .catch(function (error) {
        setFeedback(error.message);
      });
  };

  const getEmail = () => {
    if (location && location.state) {
      return location.state.email;
    }
    return null;
  };

  const showConfirmation = () => {
    if (config.verificationMethod === "link") {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2">
              Please click the link that was sent to your email to confirm your
              account. After that you should be able to
              <GLink to="/" className={classes.primary} variant="body2">
                Sign in
              </GLink>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="button"
              key="submitButton"
              onClick={onSubmit}
              fullWidth
              variant="contained"
              color="primary"
              className="submit-btn"
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Please enter the verification code we sent to your email{" "}
              {getEmail()}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="code"
              onChange={({ target }) => {
                setCode(target.value);
              }}
              variant="outlined"
              required
              fullWidth
              error={Boolean(codeError)}
              helperText={codeError}
              id="code"
              label="Code"
              placeholder="-----"
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="button"
              key="submitButton"
              onClick={onSubmit}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Continue
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              Didn't receive a verification&nbsp;code?
              <Link onClick={resendCode}>&nbsp;Re-send</Link>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">{feedback}</Typography>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <div className={classes.paper}>
      <Logo />
      <Typography component="h1" variant="h5">
        Account Verification
      </Typography>
      <form className={classes.form} noValidate>
        {showConfirmation()}

        <Box mt={5}>
          <Copyright />
        </Box>
      </form>
    </div>
  );
};

export default function Confirmation() {
  return (
    <Wrapper>
      <ConfirmationForm />
    </Wrapper>
  );
}
